<template>
  <div id="app">
    <EmsdLicense/>
  </div>
</template>

<script>
import EmsdLicense from './components/EmsdLicense.vue'

export default {
  name: 'App',
  components: {
    EmsdLicense
  }
}
</script>

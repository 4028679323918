<template>

<div>
        <div v-if="loading" class="text-center">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-if="!loading">
            <b-container fluid="sm">
                <b-row class="d-sm-none d-lg-flex" style="height: 2rem;"></b-row>
                <b-row>
                    <b-col cols="12" lg="6">
                        <p><b-img src="/logo.jpg" fluid alt="EMSD Logo"></b-img></p>
                    </b-col>
                    <b-col cols="0" lg="6">
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="8">
                        <p id="title" class="font-weight-bold">{{ i18n('sys_name') }}</p>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <div class="float-right">
                            <b-form-select v-model="selected_lang" @change="changeLang(selected_lang)">
                                <option v-for="lang in language" :key="lang" :value="lang">{{ i18n(lang) }}</option>
                            </b-form-select>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
    
            <b-container fluid="sm">
                <b-row align-v="end">
                    <b-col cols="8">
                        <p id="subtitle" class="mb-0" v-if="token && success && !reserved">{{ permit_name }}<br>{{ ordinance_name }}</p>
                    </b-col>
                    <b-col cols="4">
                        <div class="float-right">
                            <b-img v-if="valid === true" src="/tick.png" fluid alt="Approval Logo"></b-img>
                            <b-img v-if="!success || valid === false" src="/cross.png" fluid alt="Reject Logo"></b-img>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="d-sm-none d-lg-flex" style="height: 1rem;"></b-row>
            </b-container>
    
            <b-container fluid="sm" v-if="token && success && !reserved">
                <b-row>
                    <b-col>
                        <b-table-simple fixed>
                            <b-tbody>
                                <b-tr v-for="(item, index) in obj2array(info)" :key="index">
                                    <b-td class="px-0">{{ item[0] }}</b-td>
                                    <b-td class="px-0">{{ item[1] }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row align-v="stretch" style="min-height: 10rem;">
                    <b-col v-if="valid === false">
                        <p>
                        {{ i18n('note') }}
                        </p>
                        <p v-if="!valid">
                            {{ validity_reason }}
                        </p>
                        <p v-markdown2html class="markdown">
                            {{ notes }}
                        </p>
                    </b-col>
                    <b-col v-if="valid && notes">
                        <p>
                        {{ i18n('note') }}
                        </p>
                        <p v-markdown2html class="markdown">
                            {{ notes }}
                        </p>
                    </b-col>
                </b-row>
            </b-container>
    
            <b-container fluid="sm" v-if="!token || !success">
                <b-row class="text-center" style="min-height: 20rem;">
                    <b-col>
                        <p>
                        {{ i18n('not_found') }}
                        </p>
                    </b-col>
                </b-row>
            </b-container>

            <b-container fluid="sm" v-if="success && reserved">
                <b-row class="text-center" style="min-height: 20rem;">
                    <b-col>
                        <p>
                        {{ i18n('not_available') }}
                        </p>
                    </b-col>
                </b-row>
            </b-container>
    
            <b-container fluid="sm">
                <b-row class="text-center">
                    <b-col>
                        <p class="sm-text">{{ i18n('disclaimer') }}</p>
                        <p class="sm-text">{{ i18n('copyright') }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
    
</template>

<script>
import axios from "axios"

const markdown = require('markdown-it')({
    html: true,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
})

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT
const STAGE = process.env.VUE_APP_STAGE

const translation = {
    "EN": {
        "en": "English",
        "zh_hk": "繁體中文",
        "zh_cn": "簡體中文",
        "sys_name": "e-Licence System",
        "disclaimer": "This system provides members of the public to check the authenticity of information. Please limit your use of this register to the above purposes.",
        "copyright": "2021 © Last Revision Date：23 July 2021",
        "note": "Notes:",
        "not_found": "The request cannot be found, please try again later.",
        "not_available": "This licence will be available soon, please check again later.",
    },
    "ZH_HK": {
        "en": "English",
        "zh_hk": "繁體中文",
        "zh_cn": "簡體中文",
        "sys_name": "e-Licence System",
        "disclaimer": "本系統旨在為市民提供查核資料的真偽性。本系統只供上述用途使用。",
        "copyright": "2021 © 修訂日期：2021年7月23日",
        "note": "註：",
        "not_found": "找不到您的請求，請稍後再試。",
        "not_available": "本記錄即將可用，請稍後再試。",
    },
    "ZH_CN": {
        "en": "English",
        "zh_hk": "繁體中文",
        "zh_cn": "簡體中文",
        "sys_name": "e-Licence System",
        "disclaimer": "本系统旨在为市民提供查核数据的真伪性。本系统只供上述用途使用。",
        "copyright": "2021 © 修订日期：2021年7月23日",
        "note": "注：",
        "not_found": "找不到您的请求，请稍后再试。",
        "not_available": "本记录即将可用，请稍后再试。",
    },
    "default": "EN"
}

async function get_licence_data(uuid, lang) {
    let res

    try {
        res = await axios.post(`${API_ENDPOINT}/${STAGE}/license`, {
            'ref_id': uuid,
            'language': lang
        })
    } catch(e) {
        if (lang == "ZH_CN") {
            res = await axios.post(`${API_ENDPOINT}/${STAGE}/license`, {
                'ref_id': uuid,
                'language': "ZH_HK"
            })
        }
        console.log(e)
    }

    return res.data
}

async function get_licence_info(licence_code) {
    let res

    try {
        res = await axios.post(`${API_ENDPOINT}/${STAGE}/license/info`, {
            licence_code
        })
    } catch(e) {
        console.log(e)
    }

    return res.data
}

function getPathParameterByName(name) {
    let path = window.location.pathname
    let param = path.split('/')
    
    param = param.filter(x => x)
    let pos = param.indexOf(name)
    
    if (pos != -1 && param.length > 1) {
        return param[pos + 1]
    }

    return null
}

const urlParams = new URLSearchParams(window.location.search)

export default{
    name: 'EmsdLicense',
    data(){
        return{
            success: false,
            loading: true,
            reserved: false,
            token: null,
            valid: null,
            validity_reason: '',
            selected_lang: translation['default'],
            language: ["EN", "ZH_HK", "ZH_CN"],
            notes: '',
            permit_name: '',
            ordinance_name: '',
            info: {},
            status: ''
        }
    },
    directives: {
        markdown2html: {
            inserted(el) {
                // simplified example, 
                // works only for nodes without any child elements
                el.innerHTML = markdown.render(el.textContent)
            }
        }
    },
    methods: {
        i18n(key) {
            let lang = this.$data.selected_lang ?? translation['default']
            
            return translation[lang] && translation[lang][key.toLowerCase()] ? translation[lang][key.toLowerCase()] : key
        },
        obj2array(obj) {
            let res = []
        
            for (let key in obj) {
                res = [...res, {'0': key, '1': obj[key]}]
            }
        
            return res
        },
        changeLang(lang) {
            urlParams.set('lang', lang);
            window.location.search = urlParams;
        }
    },
    async mounted() {
        let lang = urlParams.get('lang') ?? translation['default']
        this.$data.selected_lang = lang

        const token = getPathParameterByName('token')
        this.$data.token = token

        this.$data.loading = true
        let res = await get_licence_data(token, lang)
        
        if (res && res.status_code && res.status_code == 20001) {
            if (res.reserved === true) {
                this.$data.reserved = res.reserved
            } else {
                this.$data.token = res.ref_id
                this.$data.valid = res.valid
                this.$data.validity_reason = res.validity_reason
                // this.$data.language = res.languages
                this.$data.info = res.result

                let info_res = await get_licence_info(res.licence_type)

                if (info_res['result'] && info_res['result'].length > 0) {
                    let info = info_res['result'][0]

                    this.$data.permit_name = info['licence_name'][lang] ?? ""
                    this.$data.ordinance_name = info['ordinance_name'][lang] ?? ""
                    this.$data.notes = info['notes'][lang] ?? ""
                }
            }

            this.$data.success = true
        } else {
            this.$data.success = false
        }

        this.$data.loading = false
    }
}
</script>

<style scoped>
#title {
    font-size: 1.6rem;
}

#subtitle {
    font-size: 1.1rem;
}

.sm-text {
    font-size: 0.8rem;
}
</style>

<style>
.markdown table {
    margin: 8px;
}

.markdown th, .markdown td {
    border: 1px solid #dcdcdc;
}

.markdown td:first-child, .markdown th:first-child {
    border-left: none;
}

.markdown td:last-child, .markdown th:last-child {
    border-right: none;
}

.markdown th {
    border-top: none;
    padding: 8px;
}

.markdown td {
    border-bottom: none;
    padding: 8px;
}
</style>